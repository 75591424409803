import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Image from "next/image";

function CmsVideo({ component, page_id = "" }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <div className="player-wrapper">
      <Image
        alt=""
        src={component.options.video.url.thumb}
        layout="fill"
        priority={true}
        className="hidden"
      />
      {show ? (
        <ReactPlayer
          id={component.id}
          controls={true}
          light={component.options.video.url.thumb}
          width="100%"
          height="100%"
          className="react-player"
          url={component.options.video.url.video}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default CmsVideo;
